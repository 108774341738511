body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.phone-icon {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.site-layout-main {
  margin: 24px 16px;
  padding: 24px;
  /* height: 80%; */
  /* overflow: auto; */
}

.layout-content {
  margin: 24px 16px;
  padding: 24px;
}

#site-content-layout {
  /* height: 81vh; */
}

.module-page-header {
  border: 1px solid rgb(235, 237, 240);
  box-sizing: border-box;
  margin: 0;
  color: rgba(0, 0, 0, .85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  padding: 16px 24px;
  background-color: #fff;
}

.module-header-heading {
  display: flex;
  justify-content: space-between;
}

.module-page-header-heading-left {
  display: flex;
  align-items: center;
  margin: 4px 0;
  overflow: hidden;
}

.module-page-header-heading-title {
  margin-right: 12px;
  margin-bottom: 0;
  color: rgba(0, 0, 0, .85);
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.module-page-header-back {
  margin-right: 16px;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
}

@media (min-width: 991px) {
  div.timeline_container div.ant-timeline-item-label {
    width: 10% !important;
  }

  div.timeline_container div.ant-timeline-item-content {
    left: calc(12% - 4px) !important;
    width: calc(88% - 4px) !important;
  }

  div.timeline_container div.ant-timeline-item-tail,
  div.ant-timeline-item-head {
    left: 12% !important;
  }
}

.ant-menu-item-group-title {
  color: rgba(255, 255, 255, 0.80) !important;
}

.ant-layout-sider {
  min-height: 100vh;
}
